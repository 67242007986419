.custom-navbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
  }
  
  .navbar-name {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .navbar-brand {
    font-size: 42px;
    /* font-weight: bold; */
  }
  
  .navbar-description {
    text-align: center;
    font-size: 16px;
    color: #777777;
    margin-bottom: 10px;
  }
  
  .navbar-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .navbar-nav .nav-link {
    font-size: 16px;
    margin: 0 10px;
  }
  .navbar-nav .nav-link.active {
    font-weight: bold;
    text-decoration: underline;
  }