h1 {
    font-size: 32px;
    font-weight: bold;
  }
h2 {
  font-size: 24px;
  font-weight: bold;
}
a {
  color: black;
  text-decoration: underline;
  transition: font-weight 0.3s;
}
a:hover {
  color: #38afe2;
  text-decoration: underline;
}

.inline-code {
  font-family: monospace; /* Use a monospace font for code */
  background-color: #f0f0f0; /* Specify your desired background color */
  padding: 2px 4px; /* Add padding for spacing */
  border: 1px solid #ccc; /* Add a border for visual separation */
}

tr {
  border-top: 1px solid #c6cbd1;
  background: #fff;
}

th,
td {
  padding: 6px 13px;
  border: 1px solid #dfe2e5;
}

table tr:nth-child(2n) {
  background: #f6f8fa;
}

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 1.1em;
  border-left: 5px solid #eee;
}